import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './pages/Home'
import DemoPage from './pages/Demopage';
const AboutUs = lazy(() => import('./pages/aboutus'));
const Personalised_Learning = lazy(() => import('./pages/index-three'));
const Pricing = lazy(() => import('./pages/pricing'));
const SpeechTherapyPage = lazy(() => import('./pages/SpeechTherapyPage'));
const TrialPlanPricing = lazy(() => import('./pages/TrialPlanPricing'));
const PaymentFailure = lazy(() => import('../src/components/PaymentFailure'));
const PaymentSuccess = lazy(() => import('../src/components/PaymentSuccess'));
const PendingPaymentPage = lazy(() => import('../src/components/PendingPaymentPage'));
const Institution = lazy(() => import('./pages/institution'));
const SignupLite = lazy(() => import('./pages/signuplite'));
const Contact = lazy(() => import('./pages/contact'));
const NewSignup = lazy(() => import('./pages/NewSignup'));
const Agent = lazy(() => import('./components/Agent/Agent'));
const Agents = lazy(() => import('./components/Agent/Agents'));


function MainRoutes() {
  return (
    <Routes>
      <Route index path='/' element={<Agent />} />
      {/* <Route path='/' element={<Home />} /> */}
      <Route path='/AboutHevolve' element={<Home />} />
      <Route path='/personalisedlearning' element={<Suspense fallback={<>...</>}> <Personalised_Learning /> </Suspense>} />
      <Route index path='/aboutus' element={<Suspense fallback={<>...</>}> <AboutUs /> </Suspense>} />
      <Route path='/Plan' element={<Suspense fallback={<>...</>}> <Pricing /> </Suspense>} />
      <Route path='/speechtherapy' element={<Suspense fallback={<>...</>}> <SpeechTherapyPage /> </Suspense>} />
      <Route path='/trialplan' element={<Suspense fallback={<>...</>}> <TrialPlanPricing /> </Suspense>} />
      <Route index path='/PaymentFailure' element={<Suspense fallback={<>...</>}> <PaymentFailure /> </Suspense>} />
      <Route index path='/PaymentSuccess' element={<Suspense fallback={<>...</>}> <PaymentSuccess /> </Suspense>} />
      <Route index path='/PendingPaymentPage' element={<Suspense fallback={<>...</>}> <PendingPaymentPage /> </Suspense>} />
      <Route index path='/contact' element={<Suspense fallback={<>...</>}> <Contact /> </Suspense>} />
      <Route index path='/institution' element={<Suspense fallback={<>...</>}> <Institution /> </Suspense>} />
      <Route index path='/institution/signup' element={<Suspense fallback={<>...</>}> <SignupLite /> </Suspense>} />
      <Route index path='/agents' element={<Suspense fallback={<>...</>}> <Agents /> </Suspense>} />
      <Route index path='/agents/:agentName' element={<Suspense fallback={<>...</>}> <Agent /> </Suspense>} />
      <Route index path='/signup' element={<Suspense fallback={<>...</>}> <NewSignup /> </Suspense>} />
    </Routes>
  );
}

export default MainRoutes;
