import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import {
    ChevronDown,
    ClipboardCopy,
    ThumbsUp,
    ThumbsDown,
    SendHorizontal,
    Image,
    FileText,
    User,
    Clock, Star, ChevronRight, Plus, HelpCircle
} from "lucide-react";
import configData from "../configData.json";
import AgentPoster from '../assets/images/AgentPoster.png';
import { animateScroll as scrollLibrary } from "react-scroll";

import autobahn from "autobahn";
import CryptoJS from 'crypto-js';

import NewHome from "./newHomeforDemo";
import OtpAuthModal from "./OtpAuthModal";


const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;









const TypeWriterForSubtitle = ({ text, duration, isIdle }) => {
    const [displayText, setDisplayText] = useState("");
    const charIndexRef = useRef(0);
    const intervalRef = useRef(null);
    const hasCompletedRef = useRef(false);

    useEffect(() => {
        // Reset when new text arrives
        if (text !== displayText) {
            charIndexRef.current = 0;
            hasCompletedRef.current = false;
            setDisplayText("");
            startAnimation();
        }

        return () => stopAnimation();
    }, [text, duration]);

    const startAnimation = () => {
        if (hasCompletedRef.current || !text || duration <= 0 || isIdle) {
            return;
        }

        stopAnimation();

        const charDelay = (duration * 1000) / text.length;
        let currentText = '';

        intervalRef.current = setInterval(() => {
            if (charIndexRef.current >= text.length) {
                stopAnimation();
                hasCompletedRef.current = true;
                return;
            }

            currentText += text[charIndexRef.current];
            setDisplayText(currentText);
            charIndexRef.current += 1;
        }, charDelay);
    };

    const stopAnimation = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    if (isIdle) {
        return text;
    }

    return <div>{displayText}</div>;
};







const ChatInterface = ({ agentData }) => {
    const navigate = useNavigate();
    const { agentName } = useParams();
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [pdffileUrl, setpdfFileUrl] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [videoUrl, setVideoUrl] = useState(

    );
    const [loading, setLoading] = useState(true);
    const [requestId, setRequestId] = useState(null)
    const [audioUrl, setAudioUrl] = useState(null);
    const messagesEndRef = useRef(null);
    const [userImage, setUserImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const connectionRef = useRef(null);
    const [displayedText, setDisplayedText] = useState('');
    const [showContent, setShowContent] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState("Disconnected");
    const [messagesauto, setMessagesauto] = useState([]);
    const [codeContent, setCodeContent] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const wsUri = "ws://aws_rasa.hertzai.com:8088/ws";
    const [error, setError] = useState(null);
    const [allAgents, setAllAgents] = useState([])
    const [currentAgent, setCurrentAgent] = useState()
    const [duration, setDuration] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [decryptedUserId, setDecryptedUserId] = useState(null);
    const [decryptedEmail, setDecryptedEmail] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const token = localStorage.getItem("access_token");


    const retryTimeoutRef = useRef(null);
    const maxRetries = 8;
    const retryDelay = 5000;
    useEffect(() => { }, [currentAgent])

    useEffect(() => {
        setCurrentAgent(agentData);

        const fetchPrompts = async () => {
            try {
                const response = await axios.get("https://mailer.hertzai.com/getprompt_all/");
                setAllAgents(response.data)
            } catch (error) {
                console.error("Error fetching prompts:", error);
            }
        };

        fetchPrompts();

    }, [agentData]);

    useEffect(() => {
        // Function to decrypt the values
        const decryptValue = (encryptedValue) => {
            try {
                const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
                const originalValue = bytes.toString(CryptoJS.enc.Utf8);
                return originalValue;
            } catch (error) {
                console.error("Failed to decrypt value:", error);
                return null;
            }
        };


        const encryptedUserId = localStorage.getItem("user_id");
        const encryptedEmail = localStorage.getItem("email_address");

        if (encryptedUserId && encryptedEmail) {
            const userId = decryptValue(encryptedUserId);
            const email = decryptValue(encryptedEmail);

            setDecryptedUserId(userId);
            setDecryptedEmail(email);


        } else {
            console.warn("No userId or email found in localStorage.");
        }
    }, [decryptedEmail, decryptedUserId]);
    const isIdleVideo = (videoUrl) => {
        const idleFiller = currentAgent?.fillers?.find(filler => filler.type === 'idle');
        return idleFiller && videoUrl === idleFiller.video_link;
    };

    const handleButtonClick = (chat) => {
        if (chat) {
            const idleFiller = chat.fillers?.find(filler => filler.type === 'idle');
            setVideoUrl(idleFiller?.video_link);
            setCurrentAgent(chat)

            navigate(`/agents/${chat.name}`);
        } else {
            console.log("Agent not found");
        }
    };




    useEffect(() => {
        if (allAgents.length > 0 && agentName) {
            const matchedAgent = allAgents.find(agent => agent.name === agentName);
            if (matchedAgent) {
                setCurrentAgent(matchedAgent)
                if (matchedAgent.fillers) {
                    const idleFiller = matchedAgent.fillers.find(filler => filler.type === 'idle');
                    if (idleFiller?.video_link) {
                        setVideoUrl(idleFiller.video_link);
                        return;
                    }
                }
                console.warn("Idle video not found in agent fillers.");
            } else {
                console.warn("No matching agent found for name:", agentName);
            }
        }
    }, [allAgents.length, agentName]);

    useEffect(() => {
        setupWebSocket();

        return () => {
            if (retryTimeoutRef.current) {
                clearTimeout(retryTimeoutRef.current);
            }

            if (connectionRef.current) {
                if (connectionRef.current.isOpen) {
                    connectionRef.current.close();
                }
                connectionRef.current = null;
            }
        };
    }, []);


    useEffect(() => {
        const handleMediaEnd = () => {
            try {
                const idleVideo = agentData?.fillers?.find(filler => filler.type === "idle");

                if (idleVideo?.video_link) {
                    setVideoUrl(idleVideo.video_link);

                    if (videoRef.current) {
                        videoRef.current.src = idleVideo.video_link;
                        videoRef.current.load();
                        videoRef.current.play();
                    }
                } else {
                    console.warn("Idle video not found in agentData.fillers.");
                }
            } catch (error) {
                console.error("Error handling media end:", error);
            }
        };

        const handleAudioEnd = () => {
            handleMediaEnd();
        };

        const currentVideoRef = videoRef.current;
        const currentAudioRef = audioRef.current;

        if (currentVideoRef) {
            currentVideoRef.addEventListener('ended', handleMediaEnd);
        }
        if (currentAudioRef) {
            currentAudioRef.addEventListener('ended', handleAudioEnd);
        }

        return () => {
            if (currentVideoRef) {
                currentVideoRef.removeEventListener('ended', handleMediaEnd);
            }
            if (currentAudioRef) {
                currentAudioRef.removeEventListener('ended', handleAudioEnd);
            }
        };
    }, [agentData, videoUrl, audioUrl]);

    const setupWebSocket = (retryCount = 0) => {
        if (retryCount >= maxRetries) {
            console.error("Max reconnection attempts reached");
            setConnectionStatus("Failed to Connect");
            return;
        }

        try {
            if (connectionRef.current && connectionRef.current.isOpen) {
                connectionRef.current.close();
            }

            const connection = new autobahn.Connection({
                url: wsUri,
                realm: "realm1",
                retry_if_unreachable: true,
                max_retries: 3,
                initial_retry_delay: 1,
                max_retry_delay: 5,
                retry_delay_growth: 1.5
            });

            connection.onopen = (session) => {
                setConnectionStatus("Connected");
                connectionRef.current = connection;

                const topics = [
                    `com.hertzai.pupit.${decryptedUserId}`,
                    `com.hertzai.hevolve.analogy.${decryptedUserId}`,
                    `com.hertzai.bookparsing.${decryptedUserId}`
                ];

                topics.forEach(topic => {
                    try {
                        session.subscribe(topic, (args) => {
                            try {
                                console.log(`Received message on topic: ${topic}`);
                                console.log("Raw message:", args);
                                const parsedArgs = JSON.parse(args);
                                console.log(topic);
                                console.log(parsedArgs);
                                const videoLink = parsedArgs[0]?.video_link?.video;

                                if (videoLink) {
                                    setVideoUrl(videoLink);
                                }

                                setMessagesauto(prev => [...prev, parsedArgs]);
                            } catch (error) {
                                console.error(`Error processing message for ${topic}:`, error);
                            }
                        }).then(
                            (subscription) => console.log(`Subscribed to ${topic} {subscription ID: ${subscription}`),
                            (error) => console.error(`Subscription failed for ${topic}:`, error)
                        );
                    } catch (error) {
                        console.error(`Error setting up subscription for ${topic}:`, error);
                    }
                });
            };

            connection.onclose = (reason, details) => {
                console.log("WebSocket connection closed:", reason, details);
                setConnectionStatus("Disconnected");

                if (reason !== "closed") {
                    retryTimeoutRef.current = setTimeout(() => {
                        setupWebSocket(retryCount + 1);
                    }, retryDelay);
                }
            };

            connection.open();

            // Default video setup
            if (agentData) {
                const idleFiller = agentData.fillers?.find(filler => filler.type === 'idle');
                const defaultVideoUrl = idleFiller?.video_link || agentData.description;
                setVideoUrl(defaultVideoUrl);
            }
        } catch (error) {
            console.error("Error setting up WebSocket connection:", error);
            retryTimeoutRef.current = setTimeout(() => {
                setupWebSocket(retryCount + 1);
            }, retryDelay);
        }
    };



    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };




    useEffect(() => {
        scrollToBottom();
    }, [messages, loading]);


    const handleLoadedMetadata = (event) => {
        const duration = event.target.duration;
        setDuration(duration);
    };
    const handleLoadedMetadataaudio = (event) => {
        const duration = event.target.duration;
        setDuration(duration);
    };
    const handlePdfSelect = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            console.error("No file selected.");
            return;
        }

        try {
            // Set the selected PDF file
            setPdfFile(file);


            var formdata = new FormData();
            formdata.append("bot_type", "book_parsing");
            formdata.append("user_id", decryptedUserId);
            formdata.append("request_id", uuidv4());
            formdata.append("file", file, file.name);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://azurekong.hertzai.com:8443/book_parsing/book_parsing_upload_api", requestOptions)
                .then(async (response) => {
                    if (!response.ok) {
                        console.error("Failed to upload PDF:", response.status, response.statusText);
                        return;
                    }

                    const result = await response.json();
                    setRequestId(result.request_id)

                    setpdfFileUrl(result.file_url);
                })
                .catch(error => {
                    console.error('Error during PDF upload process:', error);
                });





            if (connectionRef.current?.session) {
                const topicName = `com.hertzai.bookparsing.${decryptedUserId}`;
                connectionRef.current.session.subscribe(topicName, (args) => {

                    try {
                        const parsedArgs = JSON.parse(args);
                        const videoUrl = parsedArgs[0]?.video_link?.video;

                        if (videoUrl) {
                            setVideoUrl(videoUrl);
                        } else {
                            console.warn("Video URL not found in the response.");
                        }
                    } catch (error) {
                        console.error("Error parsing WebSocket message:", error);
                    }
                });
            } else {
                console.warn("WebSocket session is not available.");
            }
        } catch (error) {
            console.error("Error during PDF upload process:", error);
        }
    };




    const handleRemovePdf = () => {
        setPdfFile(null);
        setpdfFileUrl(null);
    };

    const handleImageSelect = async (event) => {
        const file = event.target.files[0];

        if (file) {
            setIsImageUploading(true);
            setUserImage(URL.createObjectURL(file));

            const formData = new FormData();
            formData.append("user_id", decryptedUserId);
            formData.append("file", file, file.name);
            formData.append("request_id", uuidv4());

            try {
                const response = await fetch(
                    "https://azurekong.hertzai.com:8443/makeit/upload_file",
                    {
                        method: "POST",
                        body: formData,
                        redirect: "follow",
                    }
                );

                if (response.ok) {
                    const result = await response.json();
                    setUserImage(result.file_url);
                    setUploadedImage(result.file_url);
                } else {
                    console.error(
                        "Failed to upload image:",
                        response.status,
                        response.statusText
                    );
                }
            } catch (error) {
                console.error("Error uploading image:", error);
            } finally {
                setIsImageUploading(false);
            }
        }
    };






    const handleRemoveImage = () => {
        setUserImage(null);
        setSelectedFile(null);
        setFileUrl(null);
    };


    const LogOutUser = () => {

        localStorage.removeItem("access_token");
        localStorage.removeItem("user_id");
        localStorage.removeItem("email_address");
        navigate('/')

    };


    const handleFocus = () => {
        if (!token) {
            setShowTooltip(true);
        }
    };

    const handleBlur = () => {
        setShowTooltip(false);
    };

    const handleSend = async () => {
        const currentUrl = window.location.href.toLowerCase();

        const isPersonalisedEndpoint =
            currentUrl.includes('"Hevolve"') ||
            currentUrl.includes('personalized') ||
            currentUrl.includes('personalised');

        if (!inputMessage.trim() && !fileUrl && !userImage) return;

        const userMessage = {
            type: "user",
            content: inputMessage,
            pdf: fileUrl || null,
            image: userImage || null
        };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setLoading(true);

        const dataToSend = JSON.stringify({
            text: inputMessage,
            user_id: decryptedUserId,
            teacher_avatar_id: agentData?.teacher_avatar_id || null,
            conversation_id: uuidv4(),
            request_id: requestId,
            prompt_id: isPersonalisedEndpoint ? null : currentAgent?.prompt_id || null,
            bot_type: currentAgent?.name || '',
        });

        setUserImage(null);
        setInputMessage("");
        setFileUrl(null);
        setupWebSocket();

        try {

            if (!token) {
                console.error("Authorization token is missing.");
                setLoading(false);
                setIsModalOpen(true);
                return;
            }

            const endpoint = isPersonalisedEndpoint
                ? configData.personalisedLearningAgent
                : configData.forAllAgents;

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: dataToSend,
            });

            if (!response.ok) {
                const errorResponse = await response.json();
                console.error("API call failed:", response.status, response.statusText);

                if (errorResponse.error === "invalid_token" ||
                    errorResponse.error_description === "The access token is invalid or has expired") {
                    console.warn("Access token expired. Removing stored data and opening login modal...");

                    localStorage.removeItem('expire_token');
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('email_address');

                    setIsModalOpen(true);
                }
                return;
            }

            // Process the response
            const dataJson = await response.json();
            const content = Array.isArray(dataJson.text)
                ? dataJson.text[0]
                : dataJson.text || '';

            const botMessage = {
                type: "assistant",
                content,
                videoUrl: dataJson.videoUrl || dataJson.video_url || null,
            };

            setMessages((prevMessages) => [...prevMessages, botMessage]);
            setVideoUrl(dataJson.video_link?.video || null);
            setAudioUrl(dataJson.video_link?.aud_url || null);

        } catch (err) {
            console.error("Unexpected error during request:", err);
        } finally {
            setLoading(false);
        }

        setPdfFile(null);
    };






    const handleKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const items = [

        {
            "prompt": "Your name is Radha, you are a sweet beautiful female .",
            "prompt_id": 54,
            "name": "Personalised Learning",
            "created_date": "2024-11-19T10:24:38",
            "request_id": "8b3e7d91-a49b-497d-8051-a3fa4ff3c53e",
            "is_public": true,
            "create_agent": false,
            "is_active": true,
            "user_id": 10077,
            "image_url": "http://aws_rasa.hertzai.com:5459/output/25dfe16e-a6a4-11ef-a097-42010aa00006.png",
            "teacher_avatar_id": 2759,
            "video_url": null,
            "video_text": "This is Static Description",
            "teacher_image_url": "https://azurekong.hertzai.com:8443/mkt-aws/txt/voice_dump/8f4c3958-9cropped_image.png",
            "description": "https://azurekong.hertzai.com:8443/mkt-azure/examples/74eaec428f4c3958-9cropped_image_pred_fls_f4203dae_bf375f78-eLily_audio_embed.mp4",
            "image_name": "8f4c3958-9cropped_image.png",
            "fillers": [
                {
                    "text": "Oops something went wrong",
                    "video_link": "https://azurekong.hertzai.com:8443/mkt-azure/examples/7293f99b8f4c3958-9cropped_image_pred_fls_289940eb_bf375f78-eLily_audio_embed.mp4",
                    "type": "internal_server_error"
                },
                {
                    "text": "",
                    "video_link": "https://azurekong.hertzai.com:8443/mkt-aws/examples/8f4c3958-9cropped_image_pred_fls_Blank_audio_embed.mp4",
                    "type": "idle"
                }
            ]
        },
        {
            "prompt_id": 49,
            "name": "Speech Therapy Agent",
            "prompt": "You are the great India itself,  you talk about greatness of India and Indiana and its diversity. ",
            "created_date": "2024-11-11T10:06:25",
            "request_id": "9ac457f0-eb8d-4869-9d24-4d445f6f6d66",
            "is_public": true,
            "create_agent": false,
            "is_active": true,
            "user_id": decryptedUserId,
            "image_url": "http://aws_rasa.hertzai.com:5459/output/32cbfdba-a17a-11ef-b355-42010aa00006.png",
            "teacher_avatar_id": 2739,
            "video_url": null,
            "video_text": "This is Static Description",
            "teacher_image_url": "https://azurekong.hertzai.com:8443/mkt-aws/txt/voice_dump/acb7ea45-0cropped_image.png",
            "description": "https://azurekong.hertzai.com:8443/mkt-azure/examples/ed5b3666acb7ea45-0cropped_image_pred_fls_a94bd0b3_2665af70-2Katie_audio_embed.mp4",
            "image_name": "acb7ea45-0cropped_image.png",
            "fillers": [
                {
                    "text": "Oops something went wrong",
                    "video_link": "https://azurekong.hertzai.com:8443/mkt-aws/examples/f6a8d897acb7ea45-0cropped_image_pred_fls_b4e1970c_2665af70-2Katie_audio_embed.mp4",
                    "type": "internal_server_error"
                },
                {
                    "text": "",
                    "video_link": "https://azurekong.hertzai.com:8443/mkt-aws/examples/acb7ea45-0cropped_image_pred_fls_Blank_audio_embed.mp4",
                    "type": "idle"
                }
            ]

        },
        {
            "prompt_id": 20,
            "name": "Spoken English Agent",
            "prompt": "talk as if you are my kid",
            "created_date": "2024-07-10T15:21:01",
            "request_id": "1c11d245-08f8-4e1a-9d2d-5c8128a0b185",
            "is_public": true,
            "create_agent": false,
            "is_active": true,
            "user_id": 10077,
            "image_url": "http://aws_rasa.hertzai.com:5459/output/cb7b4fe8-3ed8-11ef-becc-000d3af074c1.png",
            "teacher_avatar_id": 2193,
            "video_url": null,
            "video_text": "This is Static Description",
            "teacher_image_url": "https://azurekong.hertzai.com:8443/mkt-aws/txt/voice_dump/comfyinstant_89b6fbf1-2cropped2922730214132889043.png",
            "description": "https://azurekong.hertzai.com:8443/mkt-azure/examples/0b63bbb0comfyinstant_89b6fbf1-2cropped2922730214132889043_pred_fls_cc94d287_450f5ce5-3Margaret_audio_embed.mp4",
            "image_name": "comfyinstant_89b6fbf1-2cropped2922730214132889043.png",
            "fillers": [
                {
                    "text": "Oops something went wrong",
                    "video_link": "https://azurekong.hertzai.com:8443/mkt-azure/examples/5fffd4e3comfyinstant_89b6fbf1-2cropped2922730214132889043_pred_fls_4ad5022f_450f5ce5-3Margaret_audio_embed.mp4",
                    "type": "internal_server_error"
                },
                {
                    "text": "",
                    "video_link": "https://azurekong.hertzai.com:8443/mkt-azure/examples/comfyinstant_89b6fbf1-2cropped2922730214132889043_pred_fls_Blank_audio_embed.mp4",
                    "type": "idle"
                }
            ]
        }
        , {
            "prompt_id": 5,
            "name": "News Agent",
            "prompt": "You are a Teacher who responds as if you are Naruto",
            "created_date": "2024-02-08T10:46:49",
            "request_id": null,
            "is_public": true,
            "create_agent": false,
            "is_active": true,
            "user_id": 104,
            "image_url": "https://azurekong.hertzai.com:8443/mkt-azure/examples/bace4e36-0naruto.jpg",
            "teacher_avatar_id": 2759,
            "video_url": "https://azurekong.hertzai.com:8443/mkt-azure/examples/74eaec428f4c3958-9cropped_image_pred_fls_f4203dae_bf375f78-eLily_audio_embed.mp4",
            "video_text": "This is Static Description",
            "teacher_image_url": "https://azurekong.hertzai.com:8443/mkt-aws/txt/voice_dump/8f4c3958-9cropped_image.png",
            "description": "https://azurekong.hertzai.com:8443/mkt-azure/examples/74eaec428f4c3958-9cropped_image_pred_fls_f4203dae_bf375f78-eLily_audio_embed.mp4",
            "image_name": "8f4c3958-9cropped_image.png",
            "fillers": [
                {
                    "text": "Oops something went wrong",
                    "video_link": "https://azurekong.hertzai.com:8443/mkt-azure/examples/7293f99b8f4c3958-9cropped_image_pred_fls_289940eb_bf375f78-eLily_audio_embed.mp4",
                    "type": "internal_server_error"
                },
                {
                    "text": "",
                    "video_link": "https://azurekong.hertzai.com:8443/mkt-aws/examples/8f4c3958-9cropped_image_pred_fls_Blank_audio_embed.mp4",
                    "type": "idle"
                }
            ]
        },
        {
            "prompt_id": 12,
            "name": "Podcast Agent",
            "prompt": "create an alaram after an hour",
            "created_date": "2024-07-09T01:34:29",
            "request_id": "454dfsdfsdsfasf564545",
            "is_public": true,
            "create_agent": false,
            "is_active": true,
            "user_id": 10669,
            "image_url": "http://aws_rasa.hertzai.com:5459/output/8e891f44-96b6-11ef-a097-42010aa00006.png",
            "teacher_avatar_id": 1802,
            "video_url": null,
            "video_text": "This is Static Description",
            "teacher_image_url": "https://azurekong.hertzai.com:8443/mkt-aws/txt/voice_dump/27b62c6e-fcropped6488793763494973038.png",
            "description": "https://azurekong.hertzai.com:8443/mkt-azure/examples/77047ca627b62c6e-fcropped6488793763494973038_pred_fls_de571ca5_79b9078f-5Hindi_F_Tyagi_audio_embed.mp4",
            "image_name": "27b62c6e-fcropped6488793763494973038.png",
            "fillers": [
                {
                    "text": "",
                    "video_link": "https://azurekong.hertzai.com:8443/mkt-azure/examples/27b62c6e-fcropped6488793763494973038_pred_fls_Blank_audio_embed.mp4",
                    "type": "idle"
                }
            ]
        },
        {
            "prompt_id": 13,
            "name": "Story Narrator Agent",
            "prompt": "play music for 6 month old",
            "created_date": "2024-07-09T10:23:12",
            "request_id": "14417881-e4d8-467d-8201-b66552e48531",
            "is_public": true,
            "create_agent": false,
            "is_active": true,
            "user_id": 10676,
            "image_url": "http://aws_rasa.hertzai.com:5459/output/4b987996-96bb-11ef-a35b-42010aa00006.png",
            "teacher_avatar_id": 2141,
            "video_url": null,
            "video_text": "This is Static Description",
            "teacher_image_url": "https://azurekong.hertzai.com:8443/mkt-aws/txt/voice_dump/comfyinstant_235bda39-8cropped6966416360302249442.png",
            "description": "https://azurekong.hertzai.com:8443/mkt-azure/examples/43932170comfyinstant_235bda39-8cropped6966416360302249442_pred_fls_56c3e733__Abdul_Kalam-_English_Motivational_Speech_audio_embed.mp4",
            "image_name": "comfyinstant_235bda39-8cropped6966416360302249442.png",
            "fillers": [
                {
                    "text": "",
                    "video_link": "https://azurekong.hertzai.com:8443/mkt-azure/examples/comfyinstant_235bda39-8cropped6966416360302249442_pred_fls_Blank_audio_embed.mp4",
                    "type": "idle"
                }
            ]
        },
        {
            "prompt_id": 48,
            "name": "Casual Conversation Agent",
            "prompt": "Spoken English tutor",
            "created_date": "2024-11-11T10:06:26",
            "request_id": "6a65e893-644a-4049-8572-202172e50d00",
            "is_public": true,
            "create_agent": false,
            "is_active": true,
            "user_id": 10077,
            "image_url": "http://aws_rasa.hertzai.com:5459/output/8fc43ae2-a124-11ef-a35b-42010aa00006.png",
            "teacher_avatar_id": 2735,
            "video_url": null,
            "video_text": "This is Static Description",
            "teacher_image_url": "https://azurekong.hertzai.com:8443/mkt-aws/txt/voice_dump/cf9788e4-ccropped_image.png",
            "description": "https://azurekong.hertzai.com:8443/mkt-azure/examples/77a8e926cf9788e4-ccropped_image_pred_fls_a596bcb8_efe2bbf3-aLily_audio_embed.mp4",
            "image_name": "cf9788e4-ccropped_image.png",
            "fillers": [
                {
                    "text": "",
                    "video_link": "https://azurekong.hertzai.com:8443/mkt-azure/examples/cf9788e4-ccropped_image_pred_fls_Blank_audio_embed.mp4",
                    "type": "idle"
                }
            ]
        }



    ]
    const toggleContent = () => {
        setShowContent((prevState) => !prevState);
    };


    return (
        <>
            <div className="flex bg-black h-screen mb-6 overflow-hidden" >
                <div onMouseEnter={toggleContent}
                    onMouseLeave={toggleContent} className={`relative group w-[20%] sm:w-[30%] md:w-[15%] lg:w-[20%] text-gray-300 p-4 flex flex-col h-screen  ${showContent ? "bg-gray-900" : ""}`}>
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="text-2xl font-semibold text-white">HevolveAI</h1>
                    </div>


                    <div className={`absolute left-0 w-full h-screen bg-gray-900 transition-opacity duration-300 ${showContent ? "opacity-100 top-4" : "opacity-0 top-0"
                        }`}>
                        <div className="flex justify-between items-center mb-2 ">
                            <h1 className="text-2xl font-semibold text-white ml-4">HevolveAI</h1>
                        </div>

                        <button className="flex items-center gap-2 text-orange-500 mb-4 ml-4 ">
                            <Plus className="w-4 h-4" />
                            <span className="" >Start new chat</span>
                        </button>


                        <div className="mb-4 ml-4  ">
                            <h2 className="text-lg mb-2 ">Starred</h2>
                            <div className="text-sm text-gray-500 flex items-center gap-2">
                                <Star className="w-4 h-4" />
                                <span >Star chats you use often</span>
                            </div>
                        </div>


                        <div className="flex-1 ">

                            <h2 className="text-sm mb-2 ml-2">Recents</h2>
                            <div className="space-y-1">
                                {items.map((chat, index) => (
                                    <div
                                        onClick={() => handleButtonClick(chat)}
                                        key={index}
                                        className="flex items-center ml-1 justify-start gap-2 text-sm hover:bg-gray-800 p-2 rounded cursor-pointer"
                                    >
                                        <img
                                            src={
                                                chat.image_url?.startsWith("http://aws_rasa.hertzai.com")
                                                    ? chat.teacher_image_url
                                                    : AgentPoster
                                            }
                                            alt={chat?.name}
                                            className="w-6 h-6 rounded-full"
                                        />
                                        <span className="truncate">{chat?.name}</span>
                                    </div>
                                ))}
                            </div>

                            <button
                                onClick={() => navigate("/agents")}
                                className="text-sm ml-2 mt-2 hover:text-white flex justify-end  gap-1"
                            >
                                View All Agents
                                <ChevronRight className="w-4 h-4" />
                            </button>
                            <button className="flex justify-center w-full ">
                                <a href='https://play.google.com/store/apps/details?id=com.hertzai.hevolve&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                                    className="inline-block"
                                    style={{ overflow: 'hidden' }}
                                >
                                    <img
                                        alt='Get it on Google Play'
                                        src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                                        className="w-36 "
                                    />
                                </a>
                            </button>
                            <div className="mt-auto space-y-4 absolute bottom-0">

                                <div className="flex items-center justify-between">
                                    <div onClick={toggleDropdown} className="flex items-center gap-2 cursor-pointer mb-5">
                                        <button className="w-8 h-8 bg-gray-700 rounded-full flex items-center justify-center">
                                            <span className="text-white">R</span>
                                        </button>
                                        <span className="text-sm truncate">
                                            {(decryptedEmail && token && decryptedUserId) ? decryptedEmail : "Welcome! Log in "}

                                        </span>

                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* Footer */}
                    </div>
                    <div className="mt-auto space-y-4">

                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2 ">
                                <div className="w-8 h-8 bg-gray-700 rounded-full flex items-center justify-center">
                                    <span className="text-white">U</span>
                                </div>
                                <span className="text-sm truncate">
                                    {(decryptedEmail && token && decryptedUserId) ? decryptedEmail : "Welcome! Log in "}
                                </span>

                            </div>
                            <ChevronRight className="w-4 h-4" />
                        </div>


                    </div>
                    {isOpen && (
                        <div style={{ border: '1px solid gray' }}
                            className="absolute bottom-20 right-0 left-1 border-white w-64 bg-gray-900 text-white rounded-lg p-4 z-10"
                        >
                            {/* Email Section */}
                            <div className="flex items-center gap-2 mb-4 border-b border-gray-700 pb-2">
                                <div className="bg-purple-500 text-white flex items-center justify-center w-8 h-8 rounded-full">
                                    R
                                </div>
                                <div>
                                    <p className="text-sm font-medium">Personal</p>
                                    <p className="text-xs text-gray-400">Pro plan</p>
                                </div>
                            </div>

                            {/* Options List */}
                            <ul className="space-y-2">
                                <li onClick={() => navigate("/AboutHevolve")} className="cursor-pointer hover:bg-gray-800 p-2 rounded">
                                    About Hevolve
                                </li>
                                <li onClick={() => navigate("/agents")} className="cursor-pointer hover:bg-gray-800 p-2 rounded">
                                    Agents
                                </li>
                                <li onClick={() => navigate("/aboutus")} className="cursor-pointer hover:bg-gray-800 p-2 rounded">
                                    About Us
                                </li>
                                <li onClick={() => navigate('/Plan')} className="cursor-pointer hover:bg-gray-800 p-2 rounded">
                                    Pricing
                                </li>
                                <ul className=" flex justify-center items-center buy-button list-none mb-0">
                                    {decryptedUserId && token ? (
                                        <li className="inline mb-0">
                                            <span
                                                className="py-[6px] px-4 md:inline hidden items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded"
                                                style={{
                                                    background: 'linear-gradient(to right, #00e89d, #0078ff)',
                                                    backgroundImage: 'linear-gradient(to right, rgb(0, 232, 157), rgb(0, 120, 255))',
                                                    borderColor: '#00f0c5',
                                                    color: '#FFFAE8',
                                                    transition: 'background-color 0.3s ease',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={LogOutUser}
                                            >
                                                Logout
                                            </span>
                                        </li>
                                    ) : (
                                        <>
                                            <li className="inline mb-0">

                                                <RouterLink onClick={() => setIsModalOpen(true)}>
                                                    <span
                                                        className="py-[6px] px-4 md:inline hidden items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded"
                                                        style={{
                                                            background: 'linear-gradient(to right, #00e89d, #0078ff)',
                                                            backgroundImage: 'linear-gradient(to right, rgb(0, 232, 157), rgb(0, 120, 255))',
                                                            borderColor: '#00f0c5',
                                                            color: '#FFFAE8',
                                                            transition: 'background-color 0.3s ease',
                                                            cursor: 'pointer'

                                                        }}
                                                    >
                                                        Login
                                                    </span>
                                                </RouterLink>
                                                <OtpAuthModal
                                                    isOpen={isModalOpen}
                                                    onClose={() => setIsModalOpen(false)}
                                                />
                                            </li>

                                            <li className="md:inline hidden ps-1 mb-0 ">
                                                <RouterLink
                                                    to="#signup-section"
                                                    className="py-[6px] px-4 inline-block items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded text-white font-semibold"
                                                    smooth={true}
                                                    duration={500}
                                                    style={{
                                                        background: 'linear-gradient(to right, #00e89d, #0078ff)',
                                                        backgroundImage: 'linear-gradient(to right, rgb(0, 232, 157), rgb(0, 120, 255))',
                                                        borderColor: '#FFFAE8',
                                                        transition: 'background-color 0.3s ease',
                                                        cursor: 'pointer'

                                                    }}
                                                >
                                                    Signup
                                                </RouterLink>
                                            </li>
                                        </>
                                    )}
                                </ul>

                            </ul>
                        </div>
                    )}

                </div>

                < div className="flex flex-col h-screen bg-black w-full   " >

                    <div className=" h-screen w-full flex md:justify-start overflow-auto  " >
                        < div className="  flex-1 w-[60%]   " >
                            {
                                messages.length === 0 ? (
                                    <div className="h-5/6 flex items-center justify-center">
                                        <div className="text-center space-y-4 mb-1 ">

                                            <h1 className=" animate-fade-in text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-bold leading-tight mb-3">
                                                {currentAgent?.name || agentData?.name}
                                            </h1>

                                            <h2 className="text-2xl font-medium text-white-900 animate-fade-in">
                                                How can I help you today?
                                            </h2>
                                            <p className="text-white-600 animate-fade-in">
                                                I'm an AI agent. I'm here to help with various task!
                                            </p>
                                            <p className="text-white-600 animate-fade-in">
                                                {(currentAgent?.video_text === "This is Static Description" || agentData?.video_text === "This is Static Description")
                                                    ? ""
                                                    : (currentAgent?.video_text || agentData?.video_text)}

                                            </p>
                                            {!(decryptedUserId && token) && (<ul className="buy-button list-none mb-0">
                                                <li className="inline mb-0">
                                                    <RouterLink
                                                        onClick={() => setIsModalOpen(true)}
                                                        className="py-[6px] px-4 inline-block text-sm text-center rounded"
                                                        style={{
                                                            background: 'linear-gradient(to right, #00e89d, #0078ff)',
                                                            borderColor: '#00f0c5',
                                                            color: '#FFFAE8',
                                                            cursor: 'pointer',
                                                            transition: 'background-color 0.3s ease',
                                                        }}
                                                    >
                                                        Login
                                                    </RouterLink>
                                                    <OtpAuthModal
                                                        isOpen={isModalOpen}
                                                        onClose={() => setIsModalOpen(false)}
                                                    />
                                                </li>
                                                <span className="mx-4 ">OR</span>
                                                <li className="inline mb-0 ps-1">
                                                    <RouterLink
                                                        onClick={() => {
                                                            const element = document.getElementById("signup-section");
                                                            element?.scrollIntoView({ behavior: "smooth" });
                                                        }}
                                                        to="#signup-section"
                                                        className="py-[6px] px-4 inline-block text-sm text-center rounded text-white font-semibold"
                                                        style={{
                                                            background: 'linear-gradient(to right, #00e89d, #0078ff)',
                                                            borderColor: '#FFFAE8',
                                                            cursor: 'pointer',
                                                            transition: 'background-color 0.3s ease',
                                                        }}
                                                    >
                                                        Signup
                                                    </RouterLink>
                                                </li>
                                            </ul>)}



                                        </div>
                                    </div>

                                ) : (
                                    <div className="max-w-5xl mx-auto p-4 space-y-6" >
                                        {
                                            messages.map((message, index) => (
                                                <div
                                                    key={index}
                                                    className="rounded-lg p-6 shadow-sm"
                                                    style={{
                                                        backgroundColor: message.type === "user" ? "#EFEAAA" : "#212A31",
                                                        color: "#FFFFFF"
                                                    }}
                                                >

                                                    <div className="flex-1 space-y-4" >


                                                        <div
                                                            className={
                                                                message.type === "user"
                                                                    ? "text-black font-bold"
                                                                    : "text-white"
                                                            }
                                                        >
                                                            {message.type === "user" && (
                                                                <div className="space-y-2">
                                                                    <div>{message.content}</div>

                                                                    {pdffileUrl && (
                                                                        <div className="flex items-center gap-2 mt-2">
                                                                            <FileText className="w-5 h-5 text-gray-500" />
                                                                            <a
                                                                                href={pdffileUrl}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                className="text-blue-600 hover:text-blue-800 underline"
                                                                            >
                                                                                View Uploaded PDF
                                                                            </a>
                                                                        </div>
                                                                    )}

                                                                    {message.image && (
                                                                        <div className="mt-2">
                                                                            <img
                                                                                src={message.image}
                                                                                alt="Uploaded"
                                                                                className="w-16 h-16 rounded-lg shadow-md cursor-pointer"
                                                                                onClick={() => window.open(message.image, "_blank")}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}

                                                            {message.type === "assistant" && duration > 0 && (
                                                                <TypeWriterForSubtitle
                                                                    text={message.content}
                                                                    duration={duration}
                                                                    isIdle={isIdleVideo(videoUrl)}
                                                                />
                                                            )}
                                                        </div>






                                                        {
                                                            message.code && (
                                                                <button
                                                                    onClick={() => setCodeContent(message.code)}
                                                                    className="bg-blue-500 text-white px-3 py-1 rounded-md"
                                                                >
                                                                    Show Code
                                                                </button>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            ))}


                                        {
                                            loading && (
                                                <div className="flex items-center justify-start space-x-3" >
                                                    <span className="w-2 h-2 bg-gray-500 rounded-full animate-pulse" > </span>
                                                    < span className="w-2 h-2 bg-gray-500 rounded-full animate-pulse delay-200" > </span>
                                                    < span className="w-2 h-2 bg-gray-500 rounded-full animate-pulse delay-400" > </span>
                                                </div>
                                            )
                                        }



                                        <div ref={messagesEndRef} />
                                    </div>
                                )}
                        </div>


                        < div className="  flex w-[30%] justify-center md:justify-start  " >

                            {
                                videoUrl ? (
                                    <video
                                        src={videoUrl}
                                        width='400px'
                                        onLoadedMetadata={handleLoadedMetadata}
                                        className="object-conver absolute rounded-lg animate-fade-in bottom-44 right-5 "
                                        autoPlay
                                        ref={videoRef}
                                        muted
                                        controlsList="nodownload noplaybackrate"
                                        controls={false}
                                    />
                                ) : audioUrl ? (
                                    // Audio Player
                                    <div>
                                        <div className="flex items-center space-x-4 justify-center mt-5 px-4 py-4" >
                                            <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center" >
                                                <button className="text-gray-700 text-xl" >

                                                    ▶️
                                                </button>
                                            </div>

                                        </div>
                                        < div className="mt-4" >
                                            <audio
                                                ref={audioRef}
                                                src={audioUrl}
                                                className="w-full"
                                                autoPlay
                                                controlsList="nodownload noplaybackrate"
                                                controls={false}
                                                onLoadedMetadata={handleLoadedMetadataaudio}
                                            />

                                        </div>
                                    </div>
                                ) : null}











                            {/* Code Viewer */}
                            {
                                codeContent && (
                                    <div className="mt-4 bg-black p-4 rounded-lg shadow-md" >
                                        <h3 className="text-lg font-medium text-gray-900 mb-2" >
                                            Code Snippet
                                        </h3>
                                        < pre className="bg-gray-100 p-2 rounded-md text-sm overflow-auto" >
                                            <code>{codeContent} </code>
                                        </pre>
                                        < button
                                            onClick={() => setCodeContent(null)
                                            }
                                            className="mt-2 bg-red-500 text-white px-3 py-1 rounded-md"
                                        >
                                            Close Code
                                        </button>
                                    </div>
                                )}
                            {uploadedImage && (
                                <div className="mt-4 flex justify-center">
                                    <img
                                        src={uploadedImage}
                                        alt="After Audio"
                                        className="w-32 h-32 object-cover rounded-lg"
                                    />
                                </div>
                            )}
                        </div>
                    </div>


                    <div className="bg-black pt-4 border-t border-gray-700 flex justify-center mt-2 mb-2">
                        <div className="flex items-center w-[95%] relative">
                            <textarea
                                disabled={!(decryptedUserId && token)}
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                placeholder="Message..."
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onKeyPress={handleKeyPress}
                                className="text-black w-full border border-gray-200 rounded-lg pl-4 pr-16 py-3"
                                style={{ minHeight: "44px", maxHeight: "200px" }}
                            />

                            {!(decryptedUserId && token) && (
                                <div className="absolute text-white bg-black p-2 rounded-lg top-[40px] left-0">
                                    Please login to talk to agent.
                                </div>
                            )}


                            <button
                                onClick={() => document.getElementById("fileInput").click()}
                                className="text-gray-400 hover:text-gray-600 p-1"
                            >
                                <Image className="w-5 h-5" />
                            </button>

                            <button
                                onClick={() => document.getElementById("pdfInput").click()}
                                className="text-gray-400 hover:text-gray-600 p-1"
                            >
                                <FileText className="w-5 h-5" />
                            </button>
                            <button
                                disabled={!(decryptedUserId && token)} // Ensure it's disabled if user is not logged in
                                onClick={handleSend}
                                className="text-gray-400 hover:text-gray-600 p-1"
                            >
                                <SendHorizontal className="w-5 h-5" />
                            </button>
                        </div>





                        {/* File Inputs (Hidden) */}
                        <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            onChange={handleImageSelect}
                            className="hidden"
                        />
                        <input
                            type="file"
                            id="pdfInput"
                            accept="application/pdf"
                            onChange={handlePdfSelect}
                            className="hidden"
                        />
                    </div>
                    {pdfFile && (
                        <div className="relative inline-block mt-4">
                            <span className="text-gray-700">Uploaded PDF: {pdfFile.name}</span>
                            <button
                                onClick={handleRemovePdf}
                                className="text-red-600 hover:text-red-800 p-1 ml-2"
                            >
                                Remove
                            </button>
                        </div>
                    )}
                    {
                        userImage && (
                            <div className="relative inline-block mt-4" >
                                <span className="text-gray-700" > Uploaded Image: </span>
                                < img
                                    src={userImage}
                                    alt="Uploaded"
                                    className="w-16 h-16 object-cover rounded-lg shadow-md mt-2"
                                />
                                <button
                                    onClick={handleRemoveImage}
                                    className="text-red-600 hover:text-red-800 p-1 ml-2"
                                >
                                    Remove
                                </button>
                            </div>
                        )
                    }
                </div>


            </div >
            {!(decryptedUserId && token) && <NewHome />}



        </>

    );
};

export default ChatInterface;